import { TextField } from "@material-ui/core";
import { useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
      primary: {
        main: "#14213D"
      }, 
      secondary: {
        main: "#E5E5E5"
      }
  }
})

const TimerSet = (props) => { 
    const [minuteError, setMinuteError] = useState(false);
    const [secondError, setSecondError] = useState(false)
  
    return ( 
        <form className="timer-set">
        <TextField 
          error={minuteError}
          variant="outlined"
          size="small"
          type="number" 
          className="minuteInput input" 
          name="minute" 
          label="minutes"
          disabled={props.isInputDisabled}
          onFocus={(e) => {e.target.value = ""}}
          onChange={
            (e) => {
              props.setIsDisabled(false)
            if(e.target.value < 10) { 
              props.setDisplayMinutes(`0${e.target.value}`)
              setMinuteError(false)
            } else {
              props.setDisplayMinutes(e.target.value)
              setMinuteError(false)
            }; 
            props.setIsDisabled(false);
            if (e.target.value < 0) {
              props.setDisplayMinutes("00")
              setMinuteError(true)
            }
            if (e.target.value > 60 || e.target.value === 0) {
              props.setDisplayMinutes("00")
              setMinuteError(true)
            }
            if (e.target.value === "") {
              setMinuteError(false)
            }
          }
          } 
          >
        </TextField>
        <TextField 
          error={secondError}
          variant="outlined"
          size="small"
          type="number" 
          className="secondInput input" 
          name="second" 
          label="seconds"
          disabled={props.isInputDisabled}
          onFocus={(e) => {e.target.value = ""}}
          onChange={
            (e) => {
              props.setIsDisabled(false)
            if(e.target.value < 10) { 
              props.setDisplaySeconds(`0${e.target.value}`)
              setSecondError(false)
            } else {
              props.setDisplaySeconds(e.target.value)
              setSecondError(false)
            }; 
            props.setIsDisabled(false)
            if (e.target.value < 0) {
              props.setDisplaySeconds("00")
              setSecondError(true)
            }
            if (e.target.value > 60 || e.target.value === 0) {
              props.setDisplaySeconds("00")
              setSecondError(true)
            }
            if (e.target.value === "") {
              setSecondError(false)
            }
          }} 
          >
        </TextField>
        </form> 
    );
}
 
export default TimerSet;