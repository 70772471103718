import "./app.css";
import Card from '@mui/material/Card';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
      primary: {
        main: "#14213D"
      }, 
      secondary: {
        main: "#E5E5E5"
      }
  }
})

const Colors = (props) => {
    const handleClick = (e) => {   
        props.setColor(e.target.name)
    }

    return ( 
        <Card className="color-card">
            <div className="colors">
                <button className="list" style={{"background-color": "#FF6663"}} name="#FF6663" onClick={handleClick}/>
                <button className="list" style={{"background-color": "#9EC1CF"}} name="#9EC1CF" onClick={handleClick}/>
                <button className="list" style={{"background-color": "#9EE09E"}} name="#9EE09E" onClick={handleClick}/>
                <button className="list" style={{"background-color": "#CC99C9"}} name="#CC99C9" onClick={handleClick}/>
                <button className="list" style={{"background-color": "#FEB144"}} name="#FEB144" onClick={handleClick}/>
                <button className="list" style={{"background-color": "white"}} name="white" onClick={handleClick}/>
            </div>
        </Card>
    );
}
 
export default Colors;