import "./app.css";
import TimerSet from "./TimerSet";
import { useState } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from "@mui/material";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Timer = () => {
    const [displayMinutes, setDisplayMinutes] = useState("00");
    const [displaySeconds, setDisplaySeconds] = useState("00");

    const [isDisabled, setIsDisabled] = useState(true);
    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const [intervalID, setIntervalID] = useState(null); 
    const [text, setText] = useState("Start");
    const [clicks, setClicks] = useState(1);
    const [quarter, setQuarter] = useState(1)

    let userMinutes = displayMinutes;
    let userSeconds = displaySeconds;

    const handleClick = (minute, second) => {
        setIsInputDisabled(true);
        console.log(clicks)
        
        if (clicks % 2 === 1) {
            setText("Pause");
            const endTime = ((Number(minute) * 60) + Number(second)) * 1000
            const startTime = new Date().getTime();
            const futureTime = startTime + endTime;
            
            setIntervalID(
                setInterval(() => {
                    const difference = futureTime - new Date().getTime();
                    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((difference % (1000 * 60)) / (1000));
            
                    if (difference <= 0) {
                        clearInterval(intervalID);
                        setText("Start");
                        setIsInputDisabled(false);
                        setClicks(clicks - 1)
                    } 
                    else {
                        if (minutes < 10) {
                            setDisplayMinutes(`0${minutes}`);
                        } else {
                            setDisplayMinutes(minutes);
                        } if (seconds < 10) {
                            setDisplaySeconds(`0${seconds}`);
                        } else {
                            setDisplaySeconds(seconds);
                        }
                    }
                })
            )
        } else {
            setText("Resume");
            userMinutes = Number(displayMinutes);
            userSeconds = Number(displaySeconds);
            clearInterval(intervalID);
        }
        setClicks(clicks + 1);
    } 

    // fix logic so resume doesn't show up when start is pressed

    const handleQuit = () => {
        clearInterval(intervalID)
        userMinutes = 0;
        userSeconds = 0;
        setDisplayMinutes("00");
        setDisplaySeconds("00");
        setText("Start");
        setIsInputDisabled(false)
        setIsDisabled(true)
        setClicks(clicks + 1)
    }

    const handleQuarter = (input) => {
        if (input === "up") {
            setQuarter(quarter + 1);
        } else if (input === "down") {
            setQuarter(quarter - 1);
        }
        
        if (quarter <= 0) {
            setQuarter(1)
        }
    }

    return ( 
        <Container className="timer">
            <Accordion className="accordion"> 
                <AccordionSummary className="accordion-title"
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6"><b>Clock</b></Typography>
                </AccordionSummary>
                <AccordionDetails className="timer-content">
                    <Typography className="time" variant="h2">00:{displayMinutes}:{displaySeconds}</Typography>
                    <TimerSet isDisabled={!isDisabled} setIsDisabled={setIsDisabled} setDisplayMinutes={setDisplayMinutes} setDisplaySeconds={setDisplaySeconds}
                    userMinutes={userMinutes} userSeconds={userSeconds} isInputDisabled={isInputDisabled} displayMinutes={displayMinutes} displaySeconds={displaySeconds}
                    />
                    <Box className="controls">
                        <Button variant="contained" className="start btn" disabled={isDisabled} onClick={() => handleClick(userMinutes, userSeconds)}>{text}</Button>
                        <Box className="quarter">
                            <Typography className="period" variant="h6">Quarter: {quarter}</Typography>
                            <Box className="quarterControl">
                                <IconButton onClick={() => handleQuarter("up")}>
                                    <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton onClick={()=> handleQuarter("down")}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Button className="quit btn" disabled={isDisabled} onClick={handleQuit} variant="contained">End</Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}
 
export default Timer;