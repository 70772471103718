import "./app.css";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Header = () => {
    return ( 
        <AppBar>
            <Toolbar className="toolbar">
                <Typography variant="h5">Scorekeeper</Typography>
                <AccessTimeIcon />
            </Toolbar>
        </AppBar>
     );
}
 
export default Header;