import "./app.css";
import { useState } from "react";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardActions } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import Colors from "./Colors";
import Popover from '@mui/material/Popover';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
      primary: {
        main: "#14213D"
      }, 
      secondary: {
        main: "#E5E5E5"
      }
  }
})

const Item = (props) => {
    const [score, setScore] = useState(0);
    const [color, setColor] = useState("white");
    const [anchorEl, setAnchorEl] = useState(null);  

    const handleDelete = (id) => {
        props.setList(props.list.filter(item => item.id !== id));
    }
    const handleColor = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return ( 
        <ThemeProvider theme={theme}>
        <Card className="card" style={{"background-color": color}}>
            <CardActions className="actions"> 
                <IconButton onClick={() => handleDelete(props.id)} className="delete">
                    <DeleteIcon />
                </IconButton>
                <IconButton onClick={handleColor} className="color-lens">
                    <ColorLensIcon />
                </IconButton>
                <Popover 
                open={open} 
                anchorEl={anchorEl}
                onClose={handleClose}
                >
                    <Colors color={color} setColor={setColor}/>
                </Popover>
            </CardActions>
            <Box className="score">
                <IconButton onClick={() => setScore(score - 1)}>
                        <ArrowDropDownIcon fontSize="large" className="score-down arrow"/>
                </IconButton>
                <Typography variant="h3">{score}</Typography>
                <IconButton onClick={() => setScore(score + 1)}>
                    <ArrowDropUpIcon fontSize="large" className="score-up arrow"/>
                </IconButton>
            </Box>
            <TextField placeholder="Enter Team Name" className="teamName"></TextField>
        </Card>
        </ThemeProvider>
     );
}
 
export default Item;