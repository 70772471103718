import Header from "./Header";
import Item from "./Item";
import Timer from "./Timer";
import { useState } from "react";
import "./app.css";
import { Grid } from "@mui/material";
import Footer from "./Footer";
import { Container } from "@mui/material";

const App = () => {
  const [teamCount, setTeamCount] = useState(3)
  const [list, setList] = useState([
    {
      id: 1,
      teamName: "team1"
    },
    {
      id: 2,
      teamName: "team2"
    }
  ])
  
  return ( 
    <Container className="content">
      <Header className="header"/>
      <Container className="body" >
        <Timer/>
        <Grid container spacing={2} className="items">
          {list && list.map(item => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Item key={item.id} id={item.id} list={list} setList={setList}  className="item"/>
              </Grid>
            )
        })}
        </Grid>
      </Container>
        <Footer setTeamCount={setTeamCount} setList={setList} teamCount={teamCount} list={list} />
    </Container>
   );
}
 
export default App;