import "./app.css";
import { Button } from "@material-ui/core";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Footer = (props) => {
  const handleClick = () => {
    props.setList((prevList) => [
      ...prevList,
      {
        id: props.teamCount,
        teamName: `Team ${props.teamCount}`
      }
    ])
    props.setTeamCount(prevState => prevState + 1);
  }

  return ( 
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }} className="footer">
      <Toolbar>
        <Button onClick={handleClick} variant="contained" className="footer add" position="fixed" sx={{ top: 'auto', bottom: 0 }}>Add team</Button>
      </Toolbar>
    </AppBar>
  );
}
 
export default Footer;